// Gatsby supports TypeScript natively!
import React from "react"
import { PageProps, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Input from "../components/input"
import Button from "../components/Button"

const SecondPage = ({ data }) => (
  <Layout>
    <Seo title="Nuestras vías de comunicación" />
    <section className="container mx-auto px-4 mb-4">
      <h1 className="p-6 my-4 heading">Contacto</h1>
      <form
        className="max-w-md mx-auto"
        action={`https://formsubmit.co/${data.site.siteMetadata.email}`}
        method="POST"
      >
        <div className="mb-6">
          <label className="box">
            <span className="text-gray-800">Nombre</span>
            <Input id="name" type="text" name="name" required />
          </label>
        </div>
        <div className="mb-6">
          <label className="box">
            <span className="text-gray-800">Email</span>
            <Input id="email" type="email" name="email" />
          </label>
        </div>
        <div className="mb-6">
          <label className="box">
            <span className="text-gray-800">Telefono</span>
            <Input id="phone" type="tel" name="phone" />
          </label>
        </div>
        <div className="mb-6">
          <label className="box">
            <span className="text-gray-800">Mensaje</span>
            <Input
              textArea
              placeholder="Ingrese su consulta/sugerencia"
              name="message"
              rows={8}
              required
            />
          </label>
        </div>
        <div className="text-center mt-4">
          <Button type="submit" className="">
            Enviar
          </Button>
        </div>
      </form>
    </section>
  </Layout>
)

export default SecondPage

export const query = graphql`
  query ContactQuery {
    site {
      siteMetadata {
        email
        phone
        address
      }
    }
  }
`
